import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

import { useSession } from '@/hooks/useSession';

export default function StartRouting() {
	const hasCheckedSession = useRef( false );

	const { isLoading: isSessionLoading, session } = useSession();
	const { asPath, isReady, push } = useRouter();

	useEffect( () => {
		if ( !isReady ) {
			return;
		}

		// there is a hash in the URL which means it's a redirect from Azure or a
		// change password request. Either way, we need to redirect somewhere.
		if ( asPath.includes( '#' ) ) {
			// check if it's a change password
			if ( asPath.includes( 'changepassword' ) ) {
				const splitPath = asPath.split( '/' );
				const changePasswordToken = splitPath[ splitPath.length - 1 ];

				push( `/changepassword/${ changePasswordToken }` );

				return;
			}

			// otherwise, it's coming from Azure
			const searchParams = new URLSearchParams( asPath.split( '#' )[ 1 ] );

			if ( searchParams.has( 'error' ) ) {
				// there is an error parameter, so don't redirect and react to the error
				console.log( 'error=', searchParams.get( 'error' ) );
				console.log( 'error_description=', searchParams.get( 'error_description' ) );
			}

			if ( searchParams.has( 'state' ) && searchParams.has( 'id_token' ) ) {
				// these items should be handled by MSAL callback and would happen on the /auth/signin route
				push( `/auth/signin?authStateToken=${ searchParams.get( 'state' ) }&authIdToken=${ searchParams.get( 'id_token' ) }` );
			}

			return;
		}
	}, [ asPath, isReady, push ] );

	useEffect( () => {
		if ( hasCheckedSession.current || !isReady || isSessionLoading ) {
			return;
		}

		if ( session ) {
			push( '/dashboard' );
		} else {
			push( '/auth/signin' );
		}

		hasCheckedSession.current = true;
	}, [ isReady, isSessionLoading, push, session ] );

	return <></>;
}
