import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

type UseSessionProps = {
	required?: boolean;
	redirectTo?: string;
	queryConfig?: any;
};

type UseSessionExport = {
	session: any;
	isLoading: boolean;
	refetchSession: Function;
};

export async function fetchSession() {
	const res = await fetch( '/api/auth/session' );
	const session = await res.json();

	if ( Object.keys( session ).length ) {
		return session;
	}

	return null;
}

export function useSession({
	required,
	redirectTo = '/api/auth/signin?error=SessionExpired',
	queryConfig = {}
}: UseSessionProps = {} ): UseSessionExport {
	const router = useRouter();
	const { data: session, isLoading, refetch } = useQuery( [ 'session' ], fetchSession, {
		...queryConfig,
		onSettled( data, error ) {
			if ( queryConfig.onSettled ) {
				queryConfig.onSettled( data, error );
			}

			if ( data || !required ) {
				return;
			}

			router.push( redirectTo );
		}
	});

	return {
		session,
		isLoading,
		refetchSession: refetch
	};
}
